
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as orga_45taskoPapbsOm8xMeta } from "/overbookd/apps/web/pages/assignment/orga-task.vue?macro=true";
import { default as task_45orgaQqPw0bmsWZMeta } from "/overbookd/apps/web/pages/assignment/task-orga.vue?macro=true";
import { default as availabilitieszjM8ej48doMeta } from "/overbookd/apps/web/pages/availabilities.vue?macro=true";
import { default as listAPdkf8KzrIMeta } from "/overbookd/apps/web/pages/charisma/events/list.vue?macro=true";
import { default as managesxtyaSgsgnMeta } from "/overbookd/apps/web/pages/charisma/events/manage.vue?macro=true";
import { default as periodscxSfU4F1VKMeta } from "/overbookd/apps/web/pages/charisma/periods.vue?macro=true";
import { default as configuration2dEaQRXeFnMeta } from "/overbookd/apps/web/pages/configuration.vue?macro=true";
import { default as contributions4fhyZxnSqeMeta } from "/overbookd/apps/web/pages/contributions.vue?macro=true";
import { default as _91id_93sdcHKUtHsgMeta } from "/overbookd/apps/web/pages/fa/[id].vue?macro=true";
import { default as securityYS8Di6DnlaMeta } from "/overbookd/apps/web/pages/fa/dashboard/security.vue?macro=true";
import { default as index8ilZ1QKZfBMeta } from "/overbookd/apps/web/pages/fa/index.vue?macro=true";
import { default as to_45publishUhFNWGhjzbMeta } from "/overbookd/apps/web/pages/fa/to-publish.vue?macro=true";
import { default as _91id_93C08J8NSNAfMeta } from "/overbookd/apps/web/pages/ft/[id].vue?macro=true";
import { default as _420sEfuBGFnkUMeta } from "/overbookd/apps/web/pages/ft/420.vue?macro=true";
import { default as indexGnvKFsedi1Meta } from "/overbookd/apps/web/pages/ft/index.vue?macro=true";
import { default as index1g8Z3dpszVMeta } from "/overbookd/apps/web/pages/index.vue?macro=true";
import { default as loginqC1IbfsvLXMeta } from "/overbookd/apps/web/pages/login.vue?macro=true";
import { default as _91borrowId_93GSEWlLA1StMeta } from "/overbookd/apps/web/pages/logistic/borrow/[borrowId].vue?macro=true";
import { default as indexlvaMMdUtLMMeta } from "/overbookd/apps/web/pages/logistic/borrow/index.vue?macro=true";
import { default as catalogLySoihzET8Meta } from "/overbookd/apps/web/pages/logistic/catalog.vue?macro=true";
import { default as dashboardSmuInTfGwHMeta } from "/overbookd/apps/web/pages/logistic/dashboard.vue?macro=true";
import { default as inventorymfIu0vyVwbMeta } from "/overbookd/apps/web/pages/logistic/inventory.vue?macro=true";
import { default as _91purchaseId_93dfzVgtIUSGMeta } from "/overbookd/apps/web/pages/logistic/purchase/[purchaseId].vue?macro=true";
import { default as indexxPClrflCQbMeta } from "/overbookd/apps/web/pages/logistic/purchase/index.vue?macro=true";
import { default as my_45personal_45account1lTn5ELdCAMeta } from "/overbookd/apps/web/pages/my-personal-account.vue?macro=true";
import { default as need_45helpreEv2LFnC3Meta } from "/overbookd/apps/web/pages/need-help.vue?macro=true";
import { default as orga_45needFZGANruanCMeta } from "/overbookd/apps/web/pages/orga-need.vue?macro=true";
import { default as _91userId_93KRrQQCq8IPMeta } from "/overbookd/apps/web/pages/planning/[userId].vue?macro=true";
import { default as indexuGcaix4ljLMeta } from "/overbookd/apps/web/pages/planning/index.vue?macro=true";
import { default as registervH6ryFC1oGMeta } from "/overbookd/apps/web/pages/register.vue?macro=true";
import { default as staff7bEWBK7Sr9Meta } from "/overbookd/apps/web/pages/registrations/staff.vue?macro=true";
import { default as volunteerOzPpW9pqCAMeta } from "/overbookd/apps/web/pages/registrations/volunteer.vue?macro=true";
import { default as _91token_93nCZK8PfWytMeta } from "/overbookd/apps/web/pages/reset/[token].vue?macro=true";
import { default as sgL8gyBRDxOwMeta } from "/overbookd/apps/web/pages/sg.vue?macro=true";
import { default as shared_45mealsysANZt1UpUMeta } from "/overbookd/apps/web/pages/shared-meals.vue?macro=true";
import { default as catalogFBE3UUK23HMeta } from "/overbookd/apps/web/pages/signa/catalog.vue?macro=true";
import { default as locatione7niAhLb0uMeta } from "/overbookd/apps/web/pages/signa/location.vue?macro=true";
import { default as stats3Jv8Af8hrfMeta } from "/overbookd/apps/web/pages/stats.vue?macro=true";
import { default as timelineDShQvn6JxdMeta } from "/overbookd/apps/web/pages/timeline.vue?macro=true";
import { default as transactionsuEzCbCjbPfMeta } from "/overbookd/apps/web/pages/transactions.vue?macro=true";
import { default as volunteersxgmHdUdwDCMeta } from "/overbookd/apps/web/pages/volunteers.vue?macro=true";
export default [
  {
    name: "assignment-orga-task",
    path: "/assignment/orga-task",
    component: () => import("/overbookd/apps/web/pages/assignment/orga-task.vue")
  },
  {
    name: "assignment-task-orga",
    path: "/assignment/task-orga",
    component: () => import("/overbookd/apps/web/pages/assignment/task-orga.vue")
  },
  {
    name: "availabilities",
    path: "/availabilities",
    component: () => import("/overbookd/apps/web/pages/availabilities.vue")
  },
  {
    name: "charisma-events-list",
    path: "/charisma/events/list",
    component: () => import("/overbookd/apps/web/pages/charisma/events/list.vue")
  },
  {
    name: "charisma-events-manage",
    path: "/charisma/events/manage",
    component: () => import("/overbookd/apps/web/pages/charisma/events/manage.vue")
  },
  {
    name: "charisma-periods",
    path: "/charisma/periods",
    component: () => import("/overbookd/apps/web/pages/charisma/periods.vue")
  },
  {
    name: "configuration",
    path: "/configuration",
    component: () => import("/overbookd/apps/web/pages/configuration.vue")
  },
  {
    name: "contributions",
    path: "/contributions",
    component: () => import("/overbookd/apps/web/pages/contributions.vue")
  },
  {
    name: "fa-id",
    path: "/fa/:id()",
    component: () => import("/overbookd/apps/web/pages/fa/[id].vue")
  },
  {
    name: "fa-dashboard-security",
    path: "/fa/dashboard/security",
    component: () => import("/overbookd/apps/web/pages/fa/dashboard/security.vue")
  },
  {
    name: "fa",
    path: "/fa",
    component: () => import("/overbookd/apps/web/pages/fa/index.vue")
  },
  {
    name: "fa-to-publish",
    path: "/fa/to-publish",
    component: () => import("/overbookd/apps/web/pages/fa/to-publish.vue")
  },
  {
    name: "ft-id",
    path: "/ft/:id()",
    component: () => import("/overbookd/apps/web/pages/ft/[id].vue")
  },
  {
    name: "ft-420",
    path: "/ft/420",
    component: () => import("/overbookd/apps/web/pages/ft/420.vue")
  },
  {
    name: "ft",
    path: "/ft",
    component: () => import("/overbookd/apps/web/pages/ft/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/overbookd/apps/web/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginqC1IbfsvLXMeta || {},
    component: () => import("/overbookd/apps/web/pages/login.vue")
  },
  {
    name: "logistic-borrow-borrowId",
    path: "/logistic/borrow/:borrowId()",
    component: () => import("/overbookd/apps/web/pages/logistic/borrow/[borrowId].vue")
  },
  {
    name: "logistic-borrow",
    path: "/logistic/borrow",
    component: () => import("/overbookd/apps/web/pages/logistic/borrow/index.vue")
  },
  {
    name: "logistic-catalog",
    path: "/logistic/catalog",
    component: () => import("/overbookd/apps/web/pages/logistic/catalog.vue")
  },
  {
    name: "logistic-dashboard",
    path: "/logistic/dashboard",
    component: () => import("/overbookd/apps/web/pages/logistic/dashboard.vue")
  },
  {
    name: "logistic-inventory",
    path: "/logistic/inventory",
    component: () => import("/overbookd/apps/web/pages/logistic/inventory.vue")
  },
  {
    name: "logistic-purchase-purchaseId",
    path: "/logistic/purchase/:purchaseId()",
    component: () => import("/overbookd/apps/web/pages/logistic/purchase/[purchaseId].vue")
  },
  {
    name: "logistic-purchase",
    path: "/logistic/purchase",
    component: () => import("/overbookd/apps/web/pages/logistic/purchase/index.vue")
  },
  {
    name: "my-personal-account",
    path: "/my-personal-account",
    component: () => import("/overbookd/apps/web/pages/my-personal-account.vue")
  },
  {
    name: "need-help",
    path: "/need-help",
    component: () => import("/overbookd/apps/web/pages/need-help.vue")
  },
  {
    name: "orga-need",
    path: "/orga-need",
    component: () => import("/overbookd/apps/web/pages/orga-need.vue")
  },
  {
    name: "planning-userId",
    path: "/planning/:userId()",
    component: () => import("/overbookd/apps/web/pages/planning/[userId].vue")
  },
  {
    name: "planning",
    path: "/planning",
    component: () => import("/overbookd/apps/web/pages/planning/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registervH6ryFC1oGMeta || {},
    component: () => import("/overbookd/apps/web/pages/register.vue")
  },
  {
    name: "registrations-staff",
    path: "/registrations/staff",
    component: () => import("/overbookd/apps/web/pages/registrations/staff.vue")
  },
  {
    name: "registrations-volunteer",
    path: "/registrations/volunteer",
    component: () => import("/overbookd/apps/web/pages/registrations/volunteer.vue")
  },
  {
    name: "reset-token",
    path: "/reset/:token()",
    meta: _91token_93nCZK8PfWytMeta || {},
    component: () => import("/overbookd/apps/web/pages/reset/[token].vue")
  },
  {
    name: "sg",
    path: "/sg",
    component: () => import("/overbookd/apps/web/pages/sg.vue")
  },
  {
    name: "shared-meals",
    path: "/shared-meals",
    component: () => import("/overbookd/apps/web/pages/shared-meals.vue")
  },
  {
    name: "signa-catalog",
    path: "/signa/catalog",
    component: () => import("/overbookd/apps/web/pages/signa/catalog.vue")
  },
  {
    name: "signa-location",
    path: "/signa/location",
    component: () => import("/overbookd/apps/web/pages/signa/location.vue")
  },
  {
    name: "stats",
    path: "/stats",
    component: () => import("/overbookd/apps/web/pages/stats.vue")
  },
  {
    name: "timeline",
    path: "/timeline",
    component: () => import("/overbookd/apps/web/pages/timeline.vue")
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/overbookd/apps/web/pages/transactions.vue")
  },
  {
    name: "volunteers",
    path: "/volunteers",
    component: () => import("/overbookd/apps/web/pages/volunteers.vue")
  }
]