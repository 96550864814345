import { defineAsyncComponent } from 'vue'
export default {
  default: defineAsyncComponent(() => import("/overbookd/apps/web/layouts/default.vue").then(m => m.default || m)),
  header: defineAsyncComponent(() => import("/overbookd/apps/web/layouts/header/Header.vue").then(m => m.default || m)),
  "header-profile": defineAsyncComponent(() => import("/overbookd/apps/web/layouts/header/HeaderProfile.vue").then(m => m.default || m)),
  "header-logo": defineAsyncComponent(() => import("/overbookd/apps/web/layouts/header/Logo.vue").then(m => m.default || m)),
  "navigation-desktop-side-nav": defineAsyncComponent(() => import("/overbookd/apps/web/layouts/navigation/DesktopSideNav.vue").then(m => m.default || m)),
  "navigation-mobile-bottom-nav": defineAsyncComponent(() => import("/overbookd/apps/web/layouts/navigation/MobileBottomNav.vue").then(m => m.default || m)),
  "navigation-side-nav-help-item-list": defineAsyncComponent(() => import("/overbookd/apps/web/layouts/navigation/SideNavHelpItemList.vue").then(m => m.default || m)),
  "navigation-side-nav-page-item": defineAsyncComponent(() => import("/overbookd/apps/web/layouts/navigation/SideNavPageItem.vue").then(m => m.default || m)),
  "navigation-side-nav-page-list": defineAsyncComponent(() => import("/overbookd/apps/web/layouts/navigation/SideNavPageList.vue").then(m => m.default || m)),
  "navigation-side-nav-search-field": defineAsyncComponent(() => import("/overbookd/apps/web/layouts/navigation/SideNavSearchField.vue").then(m => m.default || m))
}