import payload_plugin_TNGRhRgtHg from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Ghkc8cvaH9 from "/overbookd/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_433bj4wq3ki4q7x4poj6lutjt4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nyAO8jCO2Z from "/overbookd/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_433bj4wq3ki4q7x4poj6lutjt4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uvYOQQFDlF from "/overbookd/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_433bj4wq3ki4q7x4poj6lutjt4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_abmrCumR0j from "/overbookd/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_433bj4wq3ki4q7x4poj6lutjt4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ubPfsd1kGT from "/overbookd/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_433bj4wq3ki4q7x4poj6lutjt4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kh6ZSVZ0Gv from "/overbookd/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_433bj4wq3ki4q7x4poj6lutjt4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_CIXoHSeQZf from "/overbookd/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_433bj4wq3ki4q7x4poj6lutjt4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_g8xVhRY4Dk from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/overbookd/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_Gq5IS3Xz1y from "/overbookd/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_433bj4wq3ki4q7x4poj6lutjt4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chartjs_client_N7GULTnRpX from "/overbookd/apps/web/plugins/chartjs.client.ts";
import vue_html_secure_client_vi14lb5zJW from "/overbookd/apps/web/plugins/vue-html-secure.client.ts";
import vuetify_client_69rYAaPZnF from "/overbookd/apps/web/plugins/vuetify.client.ts";
export default [
  payload_plugin_TNGRhRgtHg,
  revive_payload_client_Ghkc8cvaH9,
  unhead_nyAO8jCO2Z,
  router_uvYOQQFDlF,
  payload_client_abmrCumR0j,
  navigation_repaint_client_ubPfsd1kGT,
  check_outdated_build_client_kh6ZSVZ0Gv,
  chunk_reload_client_CIXoHSeQZf,
  plugin_vue3_g8xVhRY4Dk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Gq5IS3Xz1y,
  chartjs_client_N7GULTnRpX,
  vue_html_secure_client_vi14lb5zJW,
  vuetify_client_69rYAaPZnF
]